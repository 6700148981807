/* @tailwind base; */

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.collapse {
  visibility: collapse
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px
}

.top-0 {
  top: 0px
}

.left-0 {
  left: 0px
}

.top-full {
  top: 100%
}

.top-8 {
  top: 2rem
}

.right-8 {
  right: 2rem
}

.right-2 {
  right: 0.5rem
}

.z-10 {
  z-index: 10
}

.z-\[1057\] {
  z-index: 1057
}

.z-\[1056\] {
  z-index: 1056
}

.col-auto {
  grid-column: auto
}

.float-right {
  float: right
}

.m-auto {
  margin: auto
}

.m-0 {
  margin: 0px
}

.m-2 {
  margin: 0.5rem
}

.\!m-auto {
  margin: auto !important
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto
}

.-mx-9 {
  margin-left: -2.25rem;
  margin-right: -2.25rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mt-4 {
  margin-top: 1rem
}

.mr-auto {
  margin-right: auto
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-0 {
  margin-top: 0px
}

.mt-1 {
  margin-top: 0.25rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mb-0 {
  margin-bottom: 0px
}

.mb-4 {
  margin-bottom: 1rem
}

.mr-12 {
  margin-right: 3rem
}

.\!ml-auto {
  margin-left: auto !important
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mr-2 {
  margin-right: 0.5rem
}

.ml-auto {
  margin-left: auto
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mt-8 {
  margin-top: 2rem
}

.\!mb-3 {
  margin-bottom: 0.75rem !important
}

.mb-auto {
  margin-bottom: auto
}

.mt-12 {
  margin-top: 3rem
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.list-item {
  display: list-item
}

.hidden {
  display: none
}

.h-screen {
  height: 100vh
}

.h-3 {
  height: 0.75rem
}

.h-10 {
  height: 2.5rem
}

.h-full {
  height: 100%
}

.h-5 {
  height: 1.25rem
}

.h-52 {
  height: 13rem
}

.h-32 {
  height: 8rem
}

.max-h-screen {
  max-height: 100vh
}

.\!min-h-\[220px\] {
  min-height: 220px !important
}

.\!min-h-\[150px\] {
  min-height: 150px !important
}

.\!min-h-\[100px\] {
  min-height: 100px !important
}

.w-screen {
  width: 100vw
}

.w-3 {
  width: 0.75rem
}

.w-full {
  width: 100%
}

.w-10 {
  width: 2.5rem
}

.w-20 {
  width: 5rem
}

.w-auto {
  width: auto
}

.w-80 {
  width: 20rem
}

.w-3\/6 {
  width: 50%
}

.w-28 {
  width: 7rem
}

.w-9 {
  width: 2.25rem
}

.w-40 {
  width: 10rem
}

.w-5 {
  width: 1.25rem
}

.min-w-\[300px\] {
  min-width: 300px
}

.min-w-full {
  min-width: 100%
}

.min-w-\[150px\] {
  min-width: 150px
}

.min-w-max {
  min-width: -webkit-max-content;
  min-width: max-content
}

.\!min-w-\[200px\] {
  min-width: 200px !important
}

.min-w-\[200px\] {
  min-width: 200px
}

.min-w-fit {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content
}

.max-w-full {
  max-width: 100%
}

.max-w-fit {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content
}

.max-w-3xl {
  max-width: 48rem
}

.flex-auto {
  flex: 1 1 auto
}

.shrink {
  flex-shrink: 1
}

.flex-grow {
  flex-grow: 1
}

.grow {
  flex-grow: 1
}

.rotate-90 {
  --tw-rotate: 90deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-180 {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite
}

.cursor-pointer {
  cursor: pointer
}

.resize {
  resize: both
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.break-word {
  word-wrap: break-word;
}

.items-start {
  align-items: flex-start
}

.items-center {
  align-items: center
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-2 {
  gap: 0.5rem
}

.gap-1 {
  gap: 0.25rem
}

.gap-4 {
  gap: 1rem
}

.divide-x> :not([hidden])~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)))
}

.divide-slate-200> :not([hidden])~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-divide-opacity))
}

.overflow-auto {
  overflow: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.text-ellipsis {
  text-overflow: ellipsis
}

.rounded {
  border-radius: 0.25rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: 0.5rem
}

.\!rounded-full {
  border-radius: 9999px !important
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0px
}

.border-2 {
  border-width: 2px
}

.border-b {
  border-bottom-width: 1px
}

.border-t {
  border-top-width: 1px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-none {
  border-style: none
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity))
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity))
}

.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgb(202 138 4 / var(--tw-border-opacity))
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity))
}

.bg-transparent {
  background-color: transparent
}

.object-cover {
  object-fit: cover
}

.p-4 {
  padding: 1rem
}

.p-2 {
  padding: 0.5rem
}

.\!p-4 {
  padding: 1rem !important
}

.p-6 {
  padding: 1.5rem
}

.p-0 {
  padding: 0px
}

.p-10 {
  padding: 2.5rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pr-2 {
  padding-right: 0.5rem
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-end {
  text-align: end
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.font-bold {
  font-weight: 700
}

.font-semibold {
  font-weight: 600
}

.font-medium {
  font-weight: 500
}

.italic {
  font-style: italic
}

.leading-relaxed {
  line-height: 1.625
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity))
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity))
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity))
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity))
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity))
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity))
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity))
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity))
}

.underline {
  text-decoration-line: underline
}

.opacity-80 {
  opacity: 0.8
}

.opacity-25 {
  opacity: 0.25
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.blur {
  --tw-blur: blur(8px);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.card {
  display: block;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.card:hover {
  --tw-bg-opacity: 1;
  /* background-color: rgb(243 244 246 / var(--tw-bg-opacity)) */
}

@media (min-width: 768px) {
  .m-clip:first-child {
    margin-left: 0px !important
  }

  .m-clip:last-child {
    margin-right: 0px !important
  }
}

.first\:ml-auto:first-child {
  margin-left: auto
}

.first\:\!ml-auto:first-child {
  margin-left: auto !important
}

.last\:mr-auto:last-child {
  margin-right: auto
}

.last\:\!mr-0:last-child {
  margin-right: 0px !important
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity))
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

@media (prefers-color-scheme: dark) {
  .dark\:bg-slate-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity))
  }

  .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity))
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
  }

  .dark\:placeholder-gray-400::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(156 163 175 / var(--tw-placeholder-opacity))
  }

  .dark\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(156 163 175 / var(--tw-placeholder-opacity))
  }

  .dark\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
  }
}

.\[\&\:not\(\:last-child\)\]\:\[\&\:not\(\:first-child\)\]\:my-1:not(:first-child):not(:last-child) {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}